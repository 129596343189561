import Image from 'components/image';
import Link from 'next/link';
import Background from 'public/images/bg_login.png';
import styles from 'styles/components/SecondaryLayout.module.css';
import MainHead from 'components/layout/main/MainHead';

const SecondaryLayout = ({ children, title }) => {
  return (
    <>
      <MainHead title={title} />
      <div className={`grid overflow-hidden h-screen justify-items-center ${styles.wrapper}`}>
        <div className="h-full w-full relative">
          <p className="text-title2 absolute z-10 mx-auto inset-x-0 text-center mt-32 hidden tablet:block"><Link href="/" passHref><a className="text-white font-bold">OnBoard</a></Link></p>
          <Image src={Background} placeholder="blur" alt="Ship" layout="fill" objectFit="cover" />
        </div>
        <div className="w-full h-screen grid overflow-auto">
          <div className="max-w-screen-tablet mx-auto self-start tablet:self-center px-16 tablet:px-0 py-24">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondaryLayout;
